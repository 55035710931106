.container {
  border-bottom: 1px solid #ddd;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  display: flex;
}

.header {
  color: #000;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 600;
}

.subheader {
  color: #000;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}
