.footer {
  color: #000;
  background: #fff;
  border-top: 1px solid #ddd;
  padding: 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  overflow: hidden;
  visibility: visible !important;
}

@media print {
  .footer {
    display: none;
  }
}
