.container {
  border-bottom: 1px solid #ddd;
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
}

.container .label {
  width: calc(100% - 18px);
  margin: 9px;
}

.header {
  color: #000;
  justify-content: space-between;
  margin: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 600;
  display: flex;
}

.buffer {
  padding-bottom: 18px;
}

.content {
  margin: 0 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
}
