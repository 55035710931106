/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.container {
  border-bottom: 1px solid #ddd;
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
}
.container .label {
  margin: 9px;
  width: calc(100% - 18px);
}

.header {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 600;
  margin: 9px;
  color: #000;
  display: flex;
  justify-content: space-between;
}

.buffer {
  padding-bottom: 18px;
}

.content {
  margin: 0 18px;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  font-weight: 300;
}